@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
// Card
.card {
  display: inline-grid;
  place-items: center;
  min-height: 2.5em;
  padding: 0 0.8em;
  border-radius: 0.4em;
  border: 1px solid var(--grey);
  background-color: transparent;
  font-size: rem-calc(56);
  transition: background-color 0.2s var(--transiton-timing-ease);

  &:hover {
    color: var(--color-primary);
    background-color: var(--color-background);
    border: 1px solid var(--color-background);
  }

  @media (min-width: $tablet-min-breakpoint) {
    font-size: rem-calc(90);
  }
}

.tag {
  text-decoration: none;
}

.fontName {
  white-space: nowrap;
  font-family: var(--font);
}

// Marquee
.marquee__list {
  display: flex;
  flex-direction: row;

  padding: 0;

  list-style: none;
}

.marquee__listItem {
  margin-right: var(--spacing-xl);
}

// Keyboard styles

.marqueePaused {
  :global {
    .marquee {
      animation: none;
    }

    .marquee:first-of-type {
      display: none;
    }
  }
}

.controlButton {
  button {
    visibility: none;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  button:focus {
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
    overflow: visible;
    margin: var(--spacing-m) var(--spacing-xl);
  }
}
